let VideoEditor = {
    version: "11.0.1.172",
    releaseDD: "31",
    releaseMM: "01",
    releaseYYYY: "2025",
    size: "179.7",
    platforms: "Windows 11, 10, 8.1, 8, 7, Vista, XP (no Mac OS/ Linux support)"
};

let VideoReMaker = {
    version: "8.0.1.172",
    releaseDD: "31",
    releaseMM: "01",
    releaseYYYY: "2025",
    size: "69.4",
    platforms: "Windows 11, 10, 8.1, 8, 7, Vista, XP (no Mac OS/ Linux support)"
};

let VideoConverter = {
    version: "14.0.1.172",
    releaseDD: "31",
    releaseMM: "01",
    releaseYYYY: "2025",
    size: "84.8",
    platforms: "Windows 11, 10, 8.1, 8, 7, Vista, XP (no Mac OS/ Linux support)"
};

let MediaPlayer = {
    version: "6.0.1.172",
    releaseDD: "31",
    releaseMM: "01",
    releaseYYYY: "2025",
    size: "111",
    platforms: "Windows 11, 10, 8.1, 8, 7, Vista, XP (no Mac OS/ Linux support)"
};

let AudioEditor = {
    version: "11.0.1.172",
    releaseDD: "31",
    releaseMM: "01",
    releaseYYYY: "2025",
    size: "50.9",
    platforms: "Windows 11, 10, 8.1, 8, 7, Vista, XP (no Mac OS/ Linux support)"
};

let AudioConverter = {
    version: "11.0.1.172",
    releaseDD: "31",
    releaseMM: "01",
    releaseYYYY: "2025",
    size: "54.9",
    platforms: "Windows 11, 10, 8.1, 8, 7, Vista, XP (no Mac OS/ Linux support)"
};

let AVSImageConverter = {
    version: "7.0.1.172",
    releaseDD: "31",
    releaseMM: "01",
    releaseYYYY: "2025",
    size: "41",
    platforms: "Windows 11, 10, 8.1, 8, 7, Vista, XP (no Mac OS/ Linux support)"
};

export {VideoEditor, VideoReMaker, VideoConverter, MediaPlayer, AudioEditor, AudioConverter, AVSImageConverter};